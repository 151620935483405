import React, { FC } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { PostsQuery } from 'graphql-types';
import { Container } from './blog.styles';
import { Post } from './post/post';

const Blog: FC = () => {
  const {
    allMdx: { edges },
  }: PostsQuery = useStaticQuery(graphql`
    query Posts {
      allMdx(sort: { order: DESC, fields: frontmatter___date }) {
        edges {
          node {
            id
            frontmatter {
              date(formatString: "DD MMM YYYY")
            }
            body
          }
        }
      }
    }
  `);

  const renderPosts = edges.map(({ node }) => (
    <Post date={node?.frontmatter?.date} content={node.body} key={node.id} />
  ));

  return <Container>{renderPosts}</Container>;
};

export { Blog };
