import { useState, useEffect } from 'react';

import { getWindowWidth, createImageUrl } from './image.helpers';

export const useSrcset = (
  name: string,
  isVisible: boolean
): string | undefined => {
  const [srcset, setSrcset] = useState<string | undefined>();

  useEffect(() => {
    if (!isVisible) return;
    const width = getWindowWidth();
    if (!width) return;

    setSrcset(createImageUrl(name, width));
  }, [name, isVisible]);

  return srcset;
};
