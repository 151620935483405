import styled from '@emotion/styled';

import { GRID, CONTAINER, PADDING } from 'styles';

export const Container = styled.div`
  ${CONTAINER}
  margin: 1em auto;
  padding-left: ${PADDING.xs * 1.25}px;
  padding-right: ${PADDING.xs * 1.25}px;

  ${GRID.sm} {
    padding-left: ${PADDING.sm * 1.5}px;
    padding-right: ${PADDING.sm * 1.5}px;
  }
  ${GRID.md} {
    padding-left: ${PADDING.md * 1.75}px;
    padding-right: ${PADDING.md * 1.75}px;
  }
  ${GRID.lg} {
    padding-left: ${PADDING.lg * 2}px;
    padding-right: ${PADDING.lg * 2}px;
  }
  ${GRID.xl} {
    padding-left: ${PADDING.xl * 2}px;
    padding-right: ${PADDING.xl * 2}px;
  }
`;
