import React, { FC } from 'react';
import { InferProps, string } from 'prop-types';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import { Article, PostDate, PostContent } from './post.styles';
import { useRelativeDate, useIntersection } from './post.hooks';

const propTypes = {
  date: string.isRequired,
  content: string.isRequired,
};
type Props = InferProps<typeof propTypes>;

const Post: FC<Props> = ({ date: initialDate, content }) => {
  const { ref, isVisible } = useIntersection();
  const date = useRelativeDate(initialDate);

  return (
    <Article ref={ref} isVisible={isVisible}>
      <PostDate>{date}</PostDate>
      <PostContent>
        <MDXRenderer>{content}</MDXRenderer>
      </PostContent>
    </Article>
  );
};

Post.propTypes = propTypes;

export { Post };
