import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import { HeadQuery } from 'graphql-types';

const Head: FC = () => {
  const { site }: HeadQuery = useStaticQuery(graphql`
    query Head {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `);

  return (
    <Helmet>
      <html lang="en-GB" />
      <title>{site?.siteMetadata?.title}</title>
      <meta
        name="description"
        content={site?.siteMetadata?.description ?? undefined}
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="144x144"
        href="img/apple-touch-icon-144x144.png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="152x152"
        href="img/apple-touch-icon-152x152.png"
      />
      <link
        rel="icon"
        type="image/png"
        href="img/favicon-32x32.png"
        sizes="32x32"
      />
      <link
        rel="icon"
        type="image/png"
        href="img/favicon-16x16.png"
        sizes="16x16"
      />
      <meta name="application-name" content="Absolutely Studio" />
      <meta name="msapplication-TileColor" content="#FFFFFF" />
      <meta name="msapplication-TileImage" content="img/mstile-144x144.png" />
      <meta httpEquiv="Accept-CH" content="DPR, Viewport-Width, Width" />
    </Helmet>
  );
};

export { Head };
