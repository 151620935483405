import React, { FC, ReactNode, CSSProperties } from 'react';
import { bool, InferProps, shape, string, any } from 'prop-types';

import { withFormik } from 'formik';
import {
  animated,
  config,
  useTransition,
  UseTransitionResult,
  UseTransitionProps,
} from 'react-spring';

import { useHeight } from 'shared';
import { Container } from './contact.styles';
import { initialValues, handleSubmit, formSchema } from './form/form.helpers';
import { Form } from './form/form';
import { Thanks } from './thanks/thanks';

const propTypes = {
  status: string,
  isValid: bool.isRequired,
  isSubmitting: bool.isRequired,
  touched: shape({
    // eslint-disable-next-line react/forbid-prop-types
    from: any,
    // eslint-disable-next-line react/forbid-prop-types
    message: any,
  }).isRequired,
  errors: shape({
    // eslint-disable-next-line react/forbid-prop-types
    from: any,
    // eslint-disable-next-line react/forbid-prop-types
    message: any,
  }).isRequired,
};
type Props = InferProps<typeof propTypes>;

const Contact: FC<Props> = ({
  isSubmitting,
  isValid,
  status,
  touched,
  errors,
}) => {
  const [ref, getHeight] = useHeight<HTMLDivElement>();
  const transitions = useTransition(status !== 'sent', null, {
    leave: (item) => async (
      next: (val: CSSProperties) => Promise<void>
    ): Promise<void> => {
      if (!item) return;

      await next({
        height: getHeight(),
        transform: 'translateY(0%)',
      });
      await next({ height: 0, transform: 'translateY(150%)' });
    },
    config: config.slow,
  } as UseTransitionProps<boolean, CSSProperties>);

  const renderThanks = (): ReactNode => status === 'sent' && <Thanks />;

  const renderForm = ({
    item,
    key,
    props,
  }: UseTransitionResult<boolean, CSSProperties>): ReactNode =>
    item && (
      <animated.div ref={ref} key={key} style={props}>
        <Form
          status={status}
          isValid={isValid}
          isSubmitting={isSubmitting}
          touched={touched}
          errors={errors}
        />
      </animated.div>
    );

  return (
    <Container>
      {renderThanks()}
      {transitions.map(renderForm)}
    </Container>
  );
};

Contact.propTypes = propTypes;

const ContactFormik = withFormik({
  mapPropsToValues: () => initialValues,
  validationSchema: formSchema,
  handleSubmit,
})(Contact);

export { Contact as ContactComponent, ContactFormik as Contact };
