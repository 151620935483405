import React, { FC } from 'react';

import { Footer as FooterStyled } from './footer.styles';

const Footer: FC = () => (
  <FooterStyled>
    <a
      href="https://twitter.com/weareabsolutely"
      rel="nofollow noopener noreferrer"
      target="_blank"
    >
      Twitter
    </a>
    ,
    <a
      href="https://www.instagram.com/weareabsolutely/"
      rel="nofollow noopener noreferrer"
      target="_blank"
    >
      Instagram
    </a>
    ,
    <a
      href="https://ello.co/weareabsolutely/"
      rel="nofollow noopener noreferrer"
      target="_blank"
    >
      Ello
    </a>
    .
  </FooterStyled>
);

export { Footer };
