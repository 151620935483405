import { keyframes } from '@emotion/core';
import styled from '@emotion/styled';

import { GRID } from 'styles';

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  min-height: 300px;
  background-color: #000;
  cursor: pointer;
`;

export const Logo = styled.svg`
  width: 40px;
  height: 40px;
  fill: #fff;

  ${GRID.sm} {
    width: 60px;
    height: 60px;
  }
  ${GRID.md} {
    width: 80px;
    height: 80px;
  }
  ${GRID.lg} {
    width: 100px;
    height: 100px;
  }
`;

export const blink = keyframes`
  0%,
  48% {
    transform: scaleY(1);
  }
  49%,
  50% {
    transform: scaleY(0.1);
  }
  52%,
  100% {
    transform: scaleY(1);
  }
`;
