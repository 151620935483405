import { css } from '@emotion/core';
import styled from '@emotion/styled';
import TextAreaAutosize from 'react-autosize-textarea';
import { animated } from 'react-spring';

const ValidInput = css`
  border: 1px solid transparent;
`;
const InvalidInput = css`
  border: 1px solid red;
`;
const Input = css`
  background: none;
  border-radius: 0.25em;
  transition: background-color 0.15s ease-out;

  &:focus {
    background: #fff;
  }
`;

export const EmailFieldContainer = styled(animated.div)`
  overflow: hidden;
`;

export const EmailFields = styled.div`
  padding: 1em 0 0.5em;
`;

type FieldProps = {
  isInvalid?: boolean | null;
};

export const Field = styled.div<FieldProps>`
  label {
    display: flex;
    align-items: center;
    padding: 0;

    span {
      flex-basis: 10%;
    }

    input {
      width: 100%;
      margin: 0.1em 0;
      padding: 0.1em 0.3em;
    }
  }

  input,
  textarea {
    ${Input}
    ${({ isInvalid }) => (isInvalid ? InvalidInput : ValidInput)};
  }
`;

const TextAreaPadding = '0.4em';
export const TextArea = styled(TextAreaAutosize)`
  width: calc(100% + ${TextAreaPadding} * 2);
  margin: 1em -${TextAreaPadding} 0;
  padding: ${TextAreaPadding};
  resize: none;
`;

type ButtonProps = {
  isSubmitting: boolean;
  as: 'button' | 'a';
};

export const Button = styled.button<ButtonProps>`
  display: inline-block;
  margin: 0.5em 0 1.5em auto;
  padding: 0.25em;
  transition: color 0.2s ease;

  &:hover,
  &:active {
    color: #fff;
  }

  &:disabled {
    cursor: ${({ isSubmitting }) =>
      isSubmitting ? 'progress' : 'not-allowed'};
    color: #999;
  }
`;
