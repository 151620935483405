import React, { FC } from 'react';
import { InferProps, func } from 'prop-types';

import { css } from '@emotion/core';

import { Header as HeaderStyled, Logo, blink } from './header.styles';

const propTypes = {
  handleClick: func.isRequired,
};
type Props = InferProps<typeof propTypes>;

const Header: FC<Props> = ({ handleClick }) => (
  <HeaderStyled onClick={handleClick}>
    <Logo
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      role="img"
      aria-labelledby="logoTitle"
    >
      <title id="logoTitle">Logo</title>
      <path d="M42.7 7.3C37.8 2.4 31.9 0 25 0S12.2 2.4 7.3 7.3C2.4 12.2 0 18.1 0 25c0 6.9 2.4 12.8 7.3 17.7C12.2 47.6 18.1 50 25 50s12.8-2.4 17.7-7.3C47.6 37.8 50 31.9 50 25 50 18.1 47.6 12.2 42.7 7.3zM40.3 40.3c-4.2 4.2-9.3 6.3-15.2 6.3 -5.9 0-11-2.1-15.2-6.3S3.6 31 3.6 25.1c0-5.9 2.1-11 6.3-15.2 4.2-4.2 9.3-6.3 15.2-6.3 5.9 0 11 2.1 15.2 6.3 4.2 4.2 6.3 9.3 6.3 15.2C46.6 31 44.5 36.1 40.3 40.3z" />
      <path
        css={css`
          animation: ${blink} 4s infinite alternate backwards;
          transform-origin: center;
        `}
        d="M18.6 19.5c0 0.8-0.3 1.6-0.9 2.2 -0.6 0.6-1.3 0.9-2.2 0.9 -0.8 0-1.6-0.3-2.2-0.9 -0.6-0.6-0.9-1.4-0.9-2.2 0-0.9 0.3-1.6 0.9-2.2 0.6-0.6 1.4-0.9 2.2-0.9 0.9 0 1.6 0.3 2.2 0.9C18.3 17.9 18.6 18.6 18.6 19.5z"
      />
      <path
        css={css`
          animation: ${blink} 4s infinite alternate backwards;
          transform-origin: center;
        `}
        d="M37.6 19.5c0 0.8-0.3 1.6-0.9 2.2 -0.6 0.6-1.3 0.9-2.2 0.9 -0.9 0-1.6-0.3-2.2-0.9 -0.6-0.6-0.9-1.4-0.9-2.2 0-0.9 0.3-1.6 0.9-2.2 0.6-0.6 1.4-0.9 2.2-0.9 0.9 0 1.6 0.3 2.2 0.9C37.3 17.9 37.6 18.6 37.6 19.5z"
      />
      <path d="M13.5 32.9l2.4-1.4c2.1 3.7 5.1 5.6 9.1 5.6 4 0 7-1.8 9-5.6l2.5 1.4c-1.3 2.5-2.9 4.3-4.8 5.6 -1.9 1.3-4.1 1.9-6.7 1.9 -2.6 0-4.8-0.7-6.7-2C16.4 37.2 14.8 35.4 13.5 32.9z" />
    </Logo>
  </HeaderStyled>
);

Header.propTypes = propTypes;

export { Header };
