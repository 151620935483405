import { FormikHelpers } from 'formik';
import { string, object, InferType } from 'yup';
import { captureException, setExtra } from '@sentry/browser';

const URL = 'https://formcarry.com/s/nri8Rk9PWwNh';

export const formSchema = object()
  .shape({
    from: string().email().required(),
    message: string().required(),
  })
  .defined();

export type FormValues = InferType<typeof formSchema>;

export const initialValues: FormValues = {
  from: '',
  message:
    'Hey,\n\nWe are a design studio based in London & Manchester.\nWe do a bit of everything; branding, websites, photography, films, etc.\n\nGet in touch and say hello, or you can just send us pictures of cats.\n\nThanks,\nAbsolutely',
};
export const handleSubmit = async (
  values: FormValues,
  { setStatus }: FormikHelpers<FormValues>
): Promise<void> => {
  try {
    const { ok } = await fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(values),
    });
    if (!ok) throw new Error('Response error');

    setStatus('sent');
  } catch (e) {
    setStatus('error');

    setExtra('form', values);
    captureException(e);
  }
};
