import 'scroll-behavior-polyfill';
import 'intersection-observer';
import 'unfetch/polyfill';
import { init } from '@sentry/browser';

import './src/styles.scss';

init({
  dsn:
    'https://46460082a74542c5933eabf1eb374818@o353586.ingest.sentry.io/5205280',
  environment: process.env.NODE_ENV,
  enabled: process.env.NODE_ENV !== 'development',
});
