import React, { FC, useState } from 'react';
import { InferProps, string, node } from 'prop-types';

import { Image } from './image/image';
import { Link } from './tooltip.styles';

const propTypes = {
  imageName: string.isRequired,
  imageAlt: string.isRequired,
  children: node.isRequired,
};
type Props = InferProps<typeof propTypes>;

const Tooltip: FC<Props> = ({ imageName, imageAlt, children }) => {
  const [isVisible, setVisibility] = useState(false);

  const handleEnter = (): void => setVisibility(true);
  const handleLeave = (): void => setVisibility(false);

  const id = `${imageName}-img`;

  return (
    <>
      <Link
        onPointerEnter={handleEnter}
        onFocus={handleEnter}
        onPointerLeave={handleLeave}
        onBlur={handleLeave}
        tabIndex={0}
        aria-describedby={id}
      >
        {children}
      </Link>
      <Image id={id} name={imageName} alt={imageAlt} isVisible={isVisible} />
    </>
  );
};

Tooltip.propTypes = propTypes;

export { Tooltip };
