import { useRef, RefObject } from 'react';

export type useHeight<T extends HTMLElement> = [RefObject<T>, () => number];
export const useHeight = <T extends HTMLElement>(): useHeight<T> => {
  const ref = useRef<T | null>(null);

  const getHeight = (): number => {
    if (!ref.current) throw new Error('No element');

    return ref.current.getBoundingClientRect().height;
  };

  return [ref, getHeight];
};
