import { IMAGE_XS } from './image';

export const createImageUrl = (name: string, width: number): string =>
  `https://res.cloudinary.com/dtwviofe0/image/upload/w_${width},c_scale,dpr_auto,q_auto,f_auto/weareabsolutely/${name}`;

export const getWindowWidth = (): number | undefined => {
  const width = window.innerWidth;
  if (!width || width <= IMAGE_XS) return undefined;

  return Math.round(width / 100) * 100;
};
