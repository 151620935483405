import { css } from '@emotion/core';
import styled from '@emotion/styled';

type ImgProps = { isVisible: boolean };

const ImgHidden = css`
  visibility: hidden;
  opacity: 0;
`;
const ImgVisible = css`
  visibility: visible;
  opacity: 1;
`;
export const Img = styled.img<ImgProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  ${({ isVisible }) => (isVisible ? ImgVisible : ImgHidden)};
  object-fit: cover;
  transition: all 0.3s ease;
  z-index: 2;
  pointer-events: none;
`;
