import { useState, useEffect, useCallback } from 'react';

import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

TimeAgo.addLocale(en);
const timeAgo = new TimeAgo('en-GB');

export const useRelativeDate = (date: string): string => {
  const [relativeDate, setRelativeDate] = useState(date);

  useEffect(() => setRelativeDate(timeAgo.format(new Date(date))), [date]);

  return relativeDate;
};

export type useIntersection = {
  ref: (el: HTMLElement | null) => void;
  isVisible: null | boolean;
};
export const useIntersection = (): useIntersection => {
  const [isVisible, setIsVisible] = useState<null | boolean>(null);

  useEffect(() => setIsVisible(false), []);

  const [observer] = useState(() => {
    const handleObserve: IntersectionObserverCallback = ([
      { isIntersecting },
    ]) => {
      if (!isIntersecting) return;

      setIsVisible(true);
      observer?.disconnect();
    };

    return typeof window !== 'undefined'
      ? new IntersectionObserver(handleObserve, {
          threshold: 0,
        })
      : null;
  });

  const refCallback = useCallback(
    (el: HTMLElement | null) => {
      if (!el) return observer?.disconnect();

      return observer?.observe(el);
    },
    [observer]
  );

  return { ref: refCallback, isVisible };
};
