import React, { FC, useState, ReactNode, CSSProperties } from 'react';
import { bool, InferProps, shape, string } from 'prop-types';

import { Form as FormikForm, Field as Input } from 'formik';
import {
  config,
  useTransition,
  UseTransitionResult,
  UseTransitionProps,
} from 'react-spring';

import { useHeight } from 'shared';
import {
  Button,
  TextArea,
  Field,
  EmailFields,
  EmailFieldContainer,
} from './form.styles';

const propTypes = {
  status: string,
  isValid: bool.isRequired,
  isSubmitting: bool.isRequired,
  touched: shape({
    from: bool,
    message: bool,
  }).isRequired,
  errors: shape({
    from: string,
    message: string,
  }).isRequired,
};
type Props = InferProps<typeof propTypes>;

const Form: FC<Props> = ({
  status,
  isSubmitting,
  isValid,
  touched,
  errors,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpenClick = (): void => setIsOpen(true);

  const [ref, getHeight] = useHeight<HTMLDivElement>();
  const transitions = useTransition(isOpen, null, {
    from: { height: 0, transform: 'translateY(-100%)' },
    enter: (item) => async (
      next: (val: CSSProperties) => Promise<void>
    ): Promise<void> => {
      if (!item) return;

      await next({ height: getHeight(), transform: 'translateY(0%)' });
    },
    config: config.stiff,
  } as UseTransitionProps<boolean, CSSProperties>);

  const renderEmailFields = ({
    item,
    key,
    props,
  }: UseTransitionResult<boolean, CSSProperties>): ReactNode =>
    item && (
      <EmailFieldContainer key={key} style={props}>
        <EmailFields ref={ref}>
          <Field isInvalid={false}>
            <label htmlFor="to">
              <span>To:</span>
              <input
                type="text"
                id="to"
                placeholder="Absolutely Studio"
                disabled
                readOnly
              />
            </label>
          </Field>
          <Field isInvalid={touched.from && !!errors.from}>
            <label htmlFor="from">
              <span>From:</span>
              <Input
                type="email"
                id="from"
                name="from"
                placeholder="email@example.com"
                autoComplete="email"
                required
              />
            </label>
          </Field>
        </EmailFields>
      </EmailFieldContainer>
    );

  return (
    <FormikForm name="contact" data-netlify>
      {transitions.map(renderEmailFields)}
      <Field isInvalid={touched.message && !!errors.message}>
        <Input
          as={TextArea}
          id="message"
          name="message"
          placeholder="Message"
          required
          aria-label="Message"
        />
      </Field>
      <Button
        as={isOpen ? 'button' : 'a'}
        type={isOpen ? 'submit' : undefined}
        disabled={isSubmitting || !isValid}
        isSubmitting={isSubmitting}
        onClick={!isOpen ? handleOpenClick : undefined}
        aria-label="Email"
      >
        {status === 'error' ? 'Uh oh, try again?' : 'Email'}
      </Button>
    </FormikForm>
  );
};

Form.propTypes = propTypes;

export { Form };
