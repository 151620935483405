import React, { FC } from 'react';
import { InferProps, string, bool } from 'prop-types';

import { createImageUrl } from './image.helpers';
import { useSrcset } from './image.hooks';
import { Img } from './image.styles';

const IMAGE_XS = 550;

const propTypes = {
  id: string.isRequired,
  name: string.isRequired,
  alt: string.isRequired,
  isVisible: bool.isRequired,
};
type Props = InferProps<typeof propTypes>;

const Image: FC<Props> = ({ id, name, alt, isVisible }) => {
  const srcset = useSrcset(name, isVisible);

  return (
    <Img
      id={id}
      isVisible={isVisible}
      src={createImageUrl(name, IMAGE_XS)}
      srcSet={srcset}
      alt={alt}
      role="tooltip"
    />
  );
};

Image.propTypes = propTypes;

export { Image, IMAGE_XS };
