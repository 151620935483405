import { css } from '@emotion/core';
import styled from '@emotion/styled';

import { GUTTER, GRID } from 'styles';
import { useIntersection } from './post.hooks';

type ArticleProps = Pick<useIntersection, 'isVisible'>;

const ArticleHidden = css`
  visibility: hidden;
  opacity: 0;
  transform: translateY(125%) scale(0.9);
`;
const ArticleVisible = css`
  visibility: visible;
  opacity: 1;
  transform: none;
`;
export const Article = styled.article<ArticleProps>`
  display: flex;
  flex-wrap: wrap;
  margin: 1.5em 0;
  ${({ isVisible }) =>
    isVisible === null || isVisible === true ? ArticleVisible : ArticleHidden};
  transition: transform 0.4s ease, opacity 0.4s ease;
`;

export const PostDate = styled.div`
  flex-basis: 100%;
  flex-shrink: 0;
  flex-grow: 0;
  margin-bottom: 0.5em;
  padding: 0 ${GUTTER}px;
  color: #000;

  ${GRID.md} {
    flex-basis: 30%;
  }
`;

export const PostContent = styled.div`
  flex-basis: 100%;
  flex-shrink: 0;
  flex-grow: 0;
  padding: 0 ${GUTTER}px;

  ${GRID.md} {
    flex-basis: 70%;
  }

  p {
    margin: 0;
  }
`;
