import styled from '@emotion/styled';

import { CONTAINER } from 'styles';

export const Container = styled.div`
  overflow: hidden;
  background: #ededed;

  & > * {
    ${CONTAINER}

    form {
      display: flex;
      flex-direction: column;
    }
  }
`;
